import React from 'react';
import classNames from 'classnames';
import SectionHeader from './partials/SectionHeader';
import { Link } from 'react-router-dom';
import Image from '../elements/Image';
import NotFound from '../../assets/images/404.jpg';

export const NotFoundPage = ({
  
    className,
    topOuterDivider,
    bottomOuterDivider,
    topDivider,
    bottomDivider,
    hasBgColor,
    invertColor,
    split,
    ...props
  }) => {
    const outerClasses = classNames(
        'features-tiles section center-content-mobile reveal-from-bottom',
        topOuterDivider && 'has-top-divider',
        bottomOuterDivider && 'has-bottom-divider',
        hasBgColor && 'has-bg-color',
        invertColor && 'invert-color',
        className
      );
    
      const innerClasses = classNames(
        'features-tiles-inner section-inner',
        topDivider && 'has-top-divider',
        bottomDivider && 'has-bottom-divider',
        split && 'cta-split'
      );  

 

      const sectionHeader = {
        title: 'Uh oh. That page doesn’t exist.',
        paragraph: '',
        Image: ""
      };
    

  
  return (
    <section
      {...props}
      className={outerClasses}
    >
       <div className="container">
        <div
          className={innerClasses}
        >
          {/* <img className="center" src={NotFound} /> */}
          
          {/* <Image className="mt-0"
                  src={NotFound}
                  alt="Picture of a dog wearing hat"
                   /> */}

            <SectionHeader data={sectionHeader} className="center-content"  />
            <div className="center-content">
            {/* <div> */}
            <Image className="center-content" 
                  src={NotFound}
                  alt="Picture of a dog wearing hat"
                   /> 
            <p style={{textAlign:"center"}}>
              <Link to="/">Click here for Home </Link>
            </p>

          </div>
         

        </div>
        </div>



    </section>
    
    
  );
};

export default NotFoundPage;