import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}

const AboutUs = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {

  const outerClasses = classNames(
    'testimonial section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'testimononial-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const tilesClasses = classNames(
    'tiles-wrap',
    pushLeft && 'push-left'
  );

  const sectionHeader = {
    title: 'About Us',
    paragraph: 'Inixa Tech Consultancy incorporates a group of DevOps and Cloud Engineers up to 10 years of experience in the field. Our team is certified in various cloud/technology stacks and committed to lifelong learning so that we can deliver best value to you.'
  };

  return (
    <section id="about" 
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={tilesClasses}>

            <div className="tiles-item reveal-from-right center-content" data-reveal-delay="50">
              <div className="tiles-item-inner">
                               
                 <Image className="center-content"
                      src={require('./../../assets/images/AWS-Certified-Solutions-Architect-Associate_badge.png')}
                      alt="AWS Certified Solutions Architect - Associate"
                      width={84}
                      height={84} />
                      <p className="m-0 text-xxs center-content">
                      AWS Certified Solutions Architect - Associate
                    </p>
                    <p>

                    </p>
                  <Image
                      src={require('./../../assets/images/AWS-Certified-SysOps-Administrator-Associate_badge.png')}
                      alt="AWS Certified Sysops Administrator - Associate"
                      width={84}
                      height={84} />
                      <p className="m-0 text-xxs center-content">
                      AWS Certified Sysops Administrator - Associate
                    </p>
                    <p>

                    </p>
                   <Image
                      src={require('./../../assets/images/AWS-Certified-Cloud-Practitioner_badge.png')}
                      alt="AWS Certified Cloud Practitioner"
                      width={84}
                      height={84} />
                  <p className="m-0 text-xxs center-content">
                  AWS Certified Cloud Practitioner

                    </p>
                <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
                  <span className="testimonial-item-name text-color-high">AWS</span>
                  <span className="text-color-low"> / </span>
                  <span className="testimonial-item-link center-content">
                    <a>Amazon Web Services Certifications</a>
                  </span>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner center-content">
                
                 
              <Image
                      src={require('./../../assets/images/azure-administrator-associate-600x600.png')}
                      alt="Microsoft Certified: Azure Administrator Associate"
                      width={84}
                      height={84} />
                      <p className="m-0 text-xxs center-content">
                      Microsoft Certified: Azure Administrator Associate
                    </p>
                    <p>

                  </p>
                  <Image
                      src={require('./../../assets/images/azure-fundamentals.png')}
                      alt="Microsoft Certified: Azure Fundamentals"
                      width={84}
                      height={84} />
                      <p className="m-0 text-xxs center-content">
                      Microsoft Certified: Azure Fundamentals
                    </p>
                    <p>

                    </p>

                   <Image
                      src={require('./../../assets/images/ACP.png')}
                      alt="ACA - Alibaba Cloud Certification Associate"
                      width={84}
                      height={84} />
                  <p className="m-0 text-xxs center-content">

                  ACA - Alibaba Cloud Certification Associate

                    </p>
               
                <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
                <span className="testimonial-item-name text-color-high">AliCloud</span>
                  <span className="text-color-low"> / </span>
                  <span className="testimonial-item-link center-content">
                    <a >Azure Certifications</a>
                  </span>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-left" data-reveal-delay="100">
              <div className="tiles-item-inner center-content">
              <Image
                      src={require('./../../assets/images/cka_from_cncfsite__281_29.png')}
                      alt="CKA: Certified Kubernetes Administrator"
                      width={84}
                      height={84} />
              <p className="m-0 text-xxs">
                      CKA: Certified Kubernetes Administrator
              </p>
              <p>

              </p>
              <p>

              </p>

              <Image
                      src={require('./../../assets/images/GenericBadge.png')}
                      alt="PagerDuty Certified Incident Responder"
                      width={84}
                      height={84} />
                      <p className="m-0 text-xxs center-content">
                      PagerDuty Certified Incident Responder
                    </p>

                    <p>

                    </p>
                    <p>

                    </p>
                    
                <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
                  <span className="testimonial-item-name text-color-high">Kubernetes</span>
                  <span className="text-color-low"> / </span>
                  <span className="testimonial-item-link center-contentß">
                    <a>Other Certifications</a>
                  </span>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  );
}

AboutUs.propTypes = propTypes;
AboutUs.defaultProps = defaultProps;

export default AboutUs;