import React from 'react';
// import { Link } from 'react-router-dom';
// import NotFound from '../assets/images/404.jpg';
import NotFoundPage from '../components/sections/NotFoundPage';

// class NotFoundPage extends React.Component{
//     render(){
//         return <div>
//             <img className="center-content" src={NotFound}  />
//             <p style={{textAlign:"center"}}>
//               <Link to="/">Go to Home </Link>
//             </p>
//           </div>;
//     }
// }


const NotFound = () => {

  return (
    <>
      <NotFoundPage className="illustration-section-01" />
      {/* <div>
            <img className="center-content" src={NotFound} />
            <p style={{textAlign:"center"}}>
              <Link to="/">Go to Home </Link>
            </p>
          </div>;  */}

    </>
  );
}

export default NotFound;