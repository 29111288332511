import React, { useRef, useState} from 'react';
import emailjs from '@emailjs/browser';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import Input from '../elements/Input';
import env from "react-dotenv";
import SectionHeader from './partials/SectionHeader';
// import Modal from "react-bootstrap/Modal";

const propTypes = {
    ...SectionProps.types,
    
    split: PropTypes.bool
  }
  
  const defaultProps = {
    ...SectionProps.defaults,
    split: false
  }

export const ContactUs = ({
  
    className,
    topOuterDivider,
    bottomOuterDivider,
    topDivider,
    bottomDivider,
    hasBgColor,
    invertColor,
    split,
    ...props
  }) => {
    const outerClasses = classNames(
        'cta section center-content-mobile reveal-from-bottom',
        topOuterDivider && 'has-top-divider',
        bottomOuterDivider && 'has-bottom-divider',
        hasBgColor && 'has-bg-color',
        invertColor && 'invert-color',
        className
      );
    
      const innerClasses = classNames(
        'cta-inner section-inner',
        topDivider && 'has-top-divider',
        bottomDivider && 'has-bottom-divider',
        split && 'cta-split'
      );  
  const form = useRef();

  const [isShown, setIsShown] = useState(true);
  

  const sendEmail = (e) => {
    e.preventDefault();
    setIsShown(false);

    emailjs.sendForm(process.env.REACT_APP_SERVICE_ID, process.env.REACT_APP_TEMPLATE_ID, form.current, process.env.REACT_APP_USER_ID)
    .then((result) => {
        console.log(result.text); 
    }, (error) => {
        console.log(error.text);
        setIsShown(true); 
    });

 }

 const sectionHeader = {
  title: 'Contact Us',
  paragraph: 'Drop us a message and we will get back to you the soonest'
};

  return (

        
    <section id="contact"
    {...props}
    className={outerClasses}
  >
 
 <SectionHeader data={sectionHeader} className="center-content" />

 <div className="container">
    <div className={innerClasses} >
  
        <div className="cta-slogan">
          <h3 className="m-0" >
           Get in Touch
            </h3>
        </div>
        <div className="cta-action">
 
    <form ref={form} onSubmit={sendEmail}  >
        {isShown && (
            <Input id="name" type="text" label="name" name="name" labelHidden hasIcon="right" placeholder="Your Name" required>
              <svg width="16" height="12" xmlns="http://www.w3.org/2000/svg">
                <path d="M9 5H1c-.6 0-1 .4-1 1s.4 1 1 1h8v5l7-6-7-6v5z" fill="#376DF9" />
              </svg>
            </Input>
             )}
       {isShown && (
            <Input id="email" type="email" label="User Email" name="email" labelHidden hasIcon="right" placeholder="Your email"   required pattern="/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/">
              <svg width="16" height="12" xmlns="http://www.w3.org/2000/svg">
                <path d="M9 5H1c-.6 0-1 .4-1 1s.4 1 1 1h8v5l7-6-7-6v5z" fill="#376DF9" />
              </svg>
            </Input> 
            )}
       {isShown && (
            <Input id="message" type="text" label="message" name="message" labelHidden hasIcon="right" placeholder="Your Message" required>
              <svg width="16" height="48" xmlns="http://www.w3.org/2000/svg">
                <path d="M9 5H1c-.6 0-1 .4-1 1s.4 1 1 1h8v5l7-6-7-6v5z" fill="#376DF9" />
              </svg>
            </Input>
            )}
      {isShown && (
      <input className="button button-primary button-wide-mobile button-sm" type="submit"  tag="a" color="primary"  value="Send"   />
      )}

       {!isShown && (
         <div className="text-sm h5"> We will get back to you on your enquiry. Thank you for contacting us </div>
       )}      
    </form> 
    </div>
       
    

   </div>
   </div>
         
    </section> 
  );
};

export default ContactUs;